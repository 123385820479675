import { default as aboutBGiLjljHDjMeta } from "E:/code/my-blog/pages/about.vue?macro=true";
import { default as addTopicFqmYDJYRwZMeta } from "E:/code/my-blog/pages/admin/__com__/addTopic.vue?macro=true";
import { default as locationxbB2AByFtfMeta } from "E:/code/my-blog/pages/admin/article/components/location.vue?macro=true";
import { default as createdGJuhKWY2tMeta } from "E:/code/my-blog/pages/admin/article/create.js?macro=true";
import { default as indexACJSPok1NhMeta } from "E:/code/my-blog/pages/admin/article/index.vue?macro=true";
import { default as newFio2OekU1xMeta } from "E:/code/my-blog/pages/admin/article/new.vue?macro=true";
import { default as indexGR0OkjwRVMMeta } from "E:/code/my-blog/pages/admin/index.vue?macro=true";
import { default as logQd0HW0wkowMeta } from "E:/code/my-blog/pages/admin/log.vue?macro=true";
import { default as loginQWEUytxiP4Meta } from "E:/code/my-blog/pages/admin/login.vue?macro=true";
import { default as settingp7sJYkaqI9Meta } from "E:/code/my-blog/pages/admin/setting.vue?macro=true";
import { default as topic7OFm25iVN7Meta } from "E:/code/my-blog/pages/admin/topic.vue?macro=true";
import { default as _91id_93N0b9glJbPMMeta } from "E:/code/my-blog/pages/article/[id].vue?macro=true";
import { default as content2vxhkiGOaLMeta } from "E:/code/my-blog/pages/article/components/content.vue?macro=true";
import { default as photoAlbumLXPm8I7O8xMeta } from "E:/code/my-blog/pages/article/components/photoAlbum.vue?macro=true";
import { default as rightSiderGVDm1HWvFMeta } from "E:/code/my-blog/pages/article/components/rightSide.vue?macro=true";
import { default as albumItemZ0cttxQiFmMeta } from "E:/code/my-blog/pages/components/albumItem.vue?macro=true";
import { default as articleItemHwE60olCdJMeta } from "E:/code/my-blog/pages/components/articleItem.vue?macro=true";
import { default as momentItem958iMqWCfwMeta } from "E:/code/my-blog/pages/components/momentItem.vue?macro=true";
import { default as publishCountNrLdLKHiahMeta } from "E:/code/my-blog/pages/components/publishCount.vue?macro=true";
import { default as sideMenuOfQf4T5KAMMeta } from "E:/code/my-blog/pages/components/sideMenu.vue?macro=true";
import { default as topicListhCVCfKJ3kdMeta } from "E:/code/my-blog/pages/components/topicList.vue?macro=true";
import { default as topicTageP5IYNZ0yrMeta } from "E:/code/my-blog/pages/components/topicTag.vue?macro=true";
import { default as index5H8tisnyiwMeta } from "E:/code/my-blog/pages/footprint/index.vue?macro=true";
import { default as indexPsNxZNddOIMeta } from "E:/code/my-blog/pages/index.vue?macro=true";
import { default as movieIoLJKJV25dMeta } from "E:/code/my-blog/pages/movie.vue?macro=true";
import { default as indexlme80li2XOMeta } from "E:/code/my-blog/pages/other/index.vue?macro=true";
import { default as lifeg9k4wHiy6LMeta } from "E:/code/my-blog/pages/other/life.vue?macro=true";
import { default as earthJwXJ0QIzogMeta } from "E:/code/my-blog/pages/other/visitor/components/earth.vue?macro=true";
import { default as utilWsjrARouAZMeta } from "E:/code/my-blog/pages/other/visitor/components/util.js?macro=true";
import { default as index_46client1zF0d5C5gSMeta } from "E:/code/my-blog/pages/other/visitor/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("E:/code/my-blog/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as topicsC6WXQPz3eiMeta } from "E:/code/my-blog/pages/search/components/topics.vue?macro=true";
import { default as indexUL3UG1S95GMeta } from "E:/code/my-blog/pages/search/index.vue?macro=true";
import { default as _91id_93XKG5MxRTxiMeta } from "E:/code/my-blog/pages/topic/[id].vue?macro=true";
import { default as contentmsS1rbfa12Meta } from "E:/code/my-blog/pages/topic/components/content.vue?macro=true";
import { default as indexAA8pkzLX8NMeta } from "E:/code/my-blog/pages/topic/index.vue?macro=true";
import { default as articlemQr4UKp1naMeta } from "E:/code/my-blog/pages/webview/article.vue?macro=true";
import { default as chooseLocationMapj9Euhnx2wXMeta } from "E:/code/my-blog/pages/webview/chooseLocationMap.vue?macro=true";
export default [
  {
    name: aboutBGiLjljHDjMeta?.name ?? "about",
    path: aboutBGiLjljHDjMeta?.path ?? "/about",
    meta: aboutBGiLjljHDjMeta || {},
    alias: aboutBGiLjljHDjMeta?.alias || [],
    redirect: aboutBGiLjljHDjMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/about.vue").then(m => m.default || m)
  },
  {
    name: addTopicFqmYDJYRwZMeta?.name ?? "admin-__com__-addTopic",
    path: addTopicFqmYDJYRwZMeta?.path ?? "/admin/__com__/addTopic",
    meta: addTopicFqmYDJYRwZMeta || {},
    alias: addTopicFqmYDJYRwZMeta?.alias || [],
    redirect: addTopicFqmYDJYRwZMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/__com__/addTopic.vue").then(m => m.default || m)
  },
  {
    name: locationxbB2AByFtfMeta?.name ?? "admin-article-components-location",
    path: locationxbB2AByFtfMeta?.path ?? "/admin/article/components/location",
    meta: locationxbB2AByFtfMeta || {},
    alias: locationxbB2AByFtfMeta?.alias || [],
    redirect: locationxbB2AByFtfMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/article/components/location.vue").then(m => m.default || m)
  },
  {
    name: createdGJuhKWY2tMeta?.name ?? "admin-article-create",
    path: createdGJuhKWY2tMeta?.path ?? "/admin/article/create",
    meta: createdGJuhKWY2tMeta || {},
    alias: createdGJuhKWY2tMeta?.alias || [],
    redirect: createdGJuhKWY2tMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/article/create.js").then(m => m.default || m)
  },
  {
    name: indexACJSPok1NhMeta?.name ?? "admin-article",
    path: indexACJSPok1NhMeta?.path ?? "/admin/article",
    meta: indexACJSPok1NhMeta || {},
    alias: indexACJSPok1NhMeta?.alias || [],
    redirect: indexACJSPok1NhMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/article/index.vue").then(m => m.default || m)
  },
  {
    name: newFio2OekU1xMeta?.name ?? "admin-article-new",
    path: newFio2OekU1xMeta?.path ?? "/admin/article/new",
    meta: newFio2OekU1xMeta || {},
    alias: newFio2OekU1xMeta?.alias || [],
    redirect: newFio2OekU1xMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/article/new.vue").then(m => m.default || m)
  },
  {
    name: indexGR0OkjwRVMMeta?.name ?? "admin",
    path: indexGR0OkjwRVMMeta?.path ?? "/admin",
    meta: indexGR0OkjwRVMMeta || {},
    alias: indexGR0OkjwRVMMeta?.alias || [],
    redirect: indexGR0OkjwRVMMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: logQd0HW0wkowMeta?.name ?? "admin-log",
    path: logQd0HW0wkowMeta?.path ?? "/admin/log",
    meta: logQd0HW0wkowMeta || {},
    alias: logQd0HW0wkowMeta?.alias || [],
    redirect: logQd0HW0wkowMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/log.vue").then(m => m.default || m)
  },
  {
    name: loginQWEUytxiP4Meta?.name ?? "admin-login",
    path: loginQWEUytxiP4Meta?.path ?? "/admin/login",
    meta: loginQWEUytxiP4Meta || {},
    alias: loginQWEUytxiP4Meta?.alias || [],
    redirect: loginQWEUytxiP4Meta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: settingp7sJYkaqI9Meta?.name ?? "admin-setting",
    path: settingp7sJYkaqI9Meta?.path ?? "/admin/setting",
    meta: settingp7sJYkaqI9Meta || {},
    alias: settingp7sJYkaqI9Meta?.alias || [],
    redirect: settingp7sJYkaqI9Meta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/setting.vue").then(m => m.default || m)
  },
  {
    name: topic7OFm25iVN7Meta?.name ?? "admin-topic",
    path: topic7OFm25iVN7Meta?.path ?? "/admin/topic",
    meta: topic7OFm25iVN7Meta || {},
    alias: topic7OFm25iVN7Meta?.alias || [],
    redirect: topic7OFm25iVN7Meta?.redirect,
    component: () => import("E:/code/my-blog/pages/admin/topic.vue").then(m => m.default || m)
  },
  {
    name: _91id_93N0b9glJbPMMeta?.name ?? "article-id",
    path: _91id_93N0b9glJbPMMeta?.path ?? "/article/:id()",
    meta: _91id_93N0b9glJbPMMeta || {},
    alias: _91id_93N0b9glJbPMMeta?.alias || [],
    redirect: _91id_93N0b9glJbPMMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: content2vxhkiGOaLMeta?.name ?? "article-components-content",
    path: content2vxhkiGOaLMeta?.path ?? "/article/components/content",
    meta: content2vxhkiGOaLMeta || {},
    alias: content2vxhkiGOaLMeta?.alias || [],
    redirect: content2vxhkiGOaLMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/article/components/content.vue").then(m => m.default || m)
  },
  {
    name: photoAlbumLXPm8I7O8xMeta?.name ?? "article-components-photoAlbum",
    path: photoAlbumLXPm8I7O8xMeta?.path ?? "/article/components/photoAlbum",
    meta: photoAlbumLXPm8I7O8xMeta || {},
    alias: photoAlbumLXPm8I7O8xMeta?.alias || [],
    redirect: photoAlbumLXPm8I7O8xMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/article/components/photoAlbum.vue").then(m => m.default || m)
  },
  {
    name: rightSiderGVDm1HWvFMeta?.name ?? "article-components-rightSide",
    path: rightSiderGVDm1HWvFMeta?.path ?? "/article/components/rightSide",
    meta: rightSiderGVDm1HWvFMeta || {},
    alias: rightSiderGVDm1HWvFMeta?.alias || [],
    redirect: rightSiderGVDm1HWvFMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/article/components/rightSide.vue").then(m => m.default || m)
  },
  {
    name: albumItemZ0cttxQiFmMeta?.name ?? "components-albumItem",
    path: albumItemZ0cttxQiFmMeta?.path ?? "/components/albumItem",
    meta: albumItemZ0cttxQiFmMeta || {},
    alias: albumItemZ0cttxQiFmMeta?.alias || [],
    redirect: albumItemZ0cttxQiFmMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/albumItem.vue").then(m => m.default || m)
  },
  {
    name: articleItemHwE60olCdJMeta?.name ?? "components-articleItem",
    path: articleItemHwE60olCdJMeta?.path ?? "/components/articleItem",
    meta: articleItemHwE60olCdJMeta || {},
    alias: articleItemHwE60olCdJMeta?.alias || [],
    redirect: articleItemHwE60olCdJMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/articleItem.vue").then(m => m.default || m)
  },
  {
    name: momentItem958iMqWCfwMeta?.name ?? "components-momentItem",
    path: momentItem958iMqWCfwMeta?.path ?? "/components/momentItem",
    meta: momentItem958iMqWCfwMeta || {},
    alias: momentItem958iMqWCfwMeta?.alias || [],
    redirect: momentItem958iMqWCfwMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/momentItem.vue").then(m => m.default || m)
  },
  {
    name: publishCountNrLdLKHiahMeta?.name ?? "components-publishCount",
    path: publishCountNrLdLKHiahMeta?.path ?? "/components/publishCount",
    meta: publishCountNrLdLKHiahMeta || {},
    alias: publishCountNrLdLKHiahMeta?.alias || [],
    redirect: publishCountNrLdLKHiahMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/publishCount.vue").then(m => m.default || m)
  },
  {
    name: sideMenuOfQf4T5KAMMeta?.name ?? "components-sideMenu",
    path: sideMenuOfQf4T5KAMMeta?.path ?? "/components/sideMenu",
    meta: sideMenuOfQf4T5KAMMeta || {},
    alias: sideMenuOfQf4T5KAMMeta?.alias || [],
    redirect: sideMenuOfQf4T5KAMMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/sideMenu.vue").then(m => m.default || m)
  },
  {
    name: topicListhCVCfKJ3kdMeta?.name ?? "components-topicList",
    path: topicListhCVCfKJ3kdMeta?.path ?? "/components/topicList",
    meta: topicListhCVCfKJ3kdMeta || {},
    alias: topicListhCVCfKJ3kdMeta?.alias || [],
    redirect: topicListhCVCfKJ3kdMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/topicList.vue").then(m => m.default || m)
  },
  {
    name: topicTageP5IYNZ0yrMeta?.name ?? "components-topicTag",
    path: topicTageP5IYNZ0yrMeta?.path ?? "/components/topicTag",
    meta: topicTageP5IYNZ0yrMeta || {},
    alias: topicTageP5IYNZ0yrMeta?.alias || [],
    redirect: topicTageP5IYNZ0yrMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/components/topicTag.vue").then(m => m.default || m)
  },
  {
    name: index5H8tisnyiwMeta?.name ?? "footprint",
    path: index5H8tisnyiwMeta?.path ?? "/footprint",
    meta: index5H8tisnyiwMeta || {},
    alias: index5H8tisnyiwMeta?.alias || [],
    redirect: index5H8tisnyiwMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/footprint/index.vue").then(m => m.default || m)
  },
  {
    name: indexPsNxZNddOIMeta?.name ?? "index",
    path: indexPsNxZNddOIMeta?.path ?? "/",
    meta: indexPsNxZNddOIMeta || {},
    alias: indexPsNxZNddOIMeta?.alias || [],
    redirect: indexPsNxZNddOIMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/index.vue").then(m => m.default || m)
  },
  {
    name: movieIoLJKJV25dMeta?.name ?? "movie",
    path: movieIoLJKJV25dMeta?.path ?? "/movie",
    meta: movieIoLJKJV25dMeta || {},
    alias: movieIoLJKJV25dMeta?.alias || [],
    redirect: movieIoLJKJV25dMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/movie.vue").then(m => m.default || m)
  },
  {
    name: indexlme80li2XOMeta?.name ?? "other",
    path: indexlme80li2XOMeta?.path ?? "/other",
    meta: indexlme80li2XOMeta || {},
    alias: indexlme80li2XOMeta?.alias || [],
    redirect: indexlme80li2XOMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/other/index.vue").then(m => m.default || m)
  },
  {
    name: lifeg9k4wHiy6LMeta?.name ?? "other-life",
    path: lifeg9k4wHiy6LMeta?.path ?? "/other/life",
    meta: lifeg9k4wHiy6LMeta || {},
    alias: lifeg9k4wHiy6LMeta?.alias || [],
    redirect: lifeg9k4wHiy6LMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/other/life.vue").then(m => m.default || m)
  },
  {
    name: earthJwXJ0QIzogMeta?.name ?? "other-visitor-components-earth",
    path: earthJwXJ0QIzogMeta?.path ?? "/other/visitor/components/earth",
    meta: earthJwXJ0QIzogMeta || {},
    alias: earthJwXJ0QIzogMeta?.alias || [],
    redirect: earthJwXJ0QIzogMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/other/visitor/components/earth.vue").then(m => m.default || m)
  },
  {
    name: utilWsjrARouAZMeta?.name ?? "other-visitor-components-util",
    path: utilWsjrARouAZMeta?.path ?? "/other/visitor/components/util",
    meta: utilWsjrARouAZMeta || {},
    alias: utilWsjrARouAZMeta?.alias || [],
    redirect: utilWsjrARouAZMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/other/visitor/components/util.js").then(m => m.default || m)
  },
  {
    name: index_46client1zF0d5C5gSMeta?.name ?? "other-visitor",
    path: index_46client1zF0d5C5gSMeta?.path ?? "/other/visitor",
    meta: index_46client1zF0d5C5gSMeta || {},
    alias: index_46client1zF0d5C5gSMeta?.alias || [],
    redirect: index_46client1zF0d5C5gSMeta?.redirect,
    component: () => createClientPage(() => import("E:/code/my-blog/pages/other/visitor/index.client.vue").then(m => m.default || m))
  },
  {
    name: topicsC6WXQPz3eiMeta?.name ?? "search-components-topics",
    path: topicsC6WXQPz3eiMeta?.path ?? "/search/components/topics",
    meta: topicsC6WXQPz3eiMeta || {},
    alias: topicsC6WXQPz3eiMeta?.alias || [],
    redirect: topicsC6WXQPz3eiMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/search/components/topics.vue").then(m => m.default || m)
  },
  {
    name: indexUL3UG1S95GMeta?.name ?? "search",
    path: indexUL3UG1S95GMeta?.path ?? "/search",
    meta: indexUL3UG1S95GMeta || {},
    alias: indexUL3UG1S95GMeta?.alias || [],
    redirect: indexUL3UG1S95GMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XKG5MxRTxiMeta?.name ?? "topic-id",
    path: _91id_93XKG5MxRTxiMeta?.path ?? "/topic/:id()",
    meta: _91id_93XKG5MxRTxiMeta || {},
    alias: _91id_93XKG5MxRTxiMeta?.alias || [],
    redirect: _91id_93XKG5MxRTxiMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/topic/[id].vue").then(m => m.default || m)
  },
  {
    name: contentmsS1rbfa12Meta?.name ?? "topic-components-content",
    path: contentmsS1rbfa12Meta?.path ?? "/topic/components/content",
    meta: contentmsS1rbfa12Meta || {},
    alias: contentmsS1rbfa12Meta?.alias || [],
    redirect: contentmsS1rbfa12Meta?.redirect,
    component: () => import("E:/code/my-blog/pages/topic/components/content.vue").then(m => m.default || m)
  },
  {
    name: indexAA8pkzLX8NMeta?.name ?? "topic",
    path: indexAA8pkzLX8NMeta?.path ?? "/topic",
    meta: indexAA8pkzLX8NMeta || {},
    alias: indexAA8pkzLX8NMeta?.alias || [],
    redirect: indexAA8pkzLX8NMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: articlemQr4UKp1naMeta?.name ?? "webview-article",
    path: articlemQr4UKp1naMeta?.path ?? "/webview/article",
    meta: articlemQr4UKp1naMeta || {},
    alias: articlemQr4UKp1naMeta?.alias || [],
    redirect: articlemQr4UKp1naMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/webview/article.vue").then(m => m.default || m)
  },
  {
    name: chooseLocationMapj9Euhnx2wXMeta?.name ?? "webview-chooseLocationMap",
    path: chooseLocationMapj9Euhnx2wXMeta?.path ?? "/webview/chooseLocationMap",
    meta: chooseLocationMapj9Euhnx2wXMeta || {},
    alias: chooseLocationMapj9Euhnx2wXMeta?.alias || [],
    redirect: chooseLocationMapj9Euhnx2wXMeta?.redirect,
    component: () => import("E:/code/my-blog/pages/webview/chooseLocationMap.vue").then(m => m.default || m)
  }
]