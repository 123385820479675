import revive_payload_client_4sVQNw7RlN from "E:/code/my-blog/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "E:/code/my-blog/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/code/my-blog/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "E:/code/my-blog/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "E:/code/my-blog/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "E:/code/my-blog/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "E:/code/my-blog/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "E:/code/my-blog/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "E:/code/my-blog/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "E:/code/my-blog/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "E:/code/my-blog/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vconsole_6b69toxJWo from "E:/code/my-blog/plugins/vconsole.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  vconsole_6b69toxJWo
]